<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" :loading="loading">
        <v-sheet class="title text-center pa-4 elevation-2 rounded"
          >{{ room_detail.room_name }}
          <v-row
            align="center"
            justify="center"
            class="d-flex mt-5"
            style="min-height: 150px"
          >
            <div class="col-md-4" v-for="i in 4" :key="i">
              <v-card
                v-for="booth in booths[i - 1]"
                :key="booth"
                class="mx-auto my-3"
                elevation="12"
              >
                <v-card-title>
                  <span class="body-2">{{ `Booth : ${booth}` }}</span>
                  <v-spacer></v-spacer>
                  <v-switch
                    v-model="booth_data[booth]['is_gold']"
                    @change="assignBooth(booth_data[booth],booth)"
                    label="Gold"
                  ></v-switch>
                </v-card-title>
                <v-card-text :class="['d-flex', booth && booth.startsWith('C') ? 'flex-row-reverse' : '']">
                  <v-autocomplete
                    v-show="booth_data[booth]['is_gold']"
                    v-model="booth_data[booth]['vendor_ids']['right']"
                    outlined
                    dense
                    label="Select Vendor"
                    :items="vendors"
                    item-text="company_name"
                    item-value="id"
                    :error-messages="form_error.room_id"
                    @change="assignBooth(booth_data[booth],booth,booth_data[booth]['vendor_ids']['right'],'right')"
                    :clearable="true"
                    class="mr-2"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-model="booth_data[booth]['vendor_ids']['left']"
                    outlined
                    dense
                    label="Select Vendor"
                    :items="vendors"
                    item-text="company_name"
                    item-value="id"
                    :error-messages="form_error.room_id"
                    @change="assignBooth(booth_data[booth],booth,booth_data[booth]['vendor_ids']['left'],'left')"
                    :clearable="true"
                  ></v-autocomplete>
                </v-card-text>
              </v-card>
            </div>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
    <v-btn small color="secondary" fixed fab bottom left to="/rooms">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
  </v-container>
</template>
<script>
import { mapMutations } from "vuex";
export default {
  name: "AddVendor",
  data() {
    return {
      selected: "",
      form_data: {},
      form_error: {},
      vendors: [],
      booth_data: {
        A1 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        A2 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        A3 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        A4 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        B1 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        B2 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        B3 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        B4 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        C1 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        C2 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        C3 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        },
        C4 : {
          vendor_ids : {
            left : null,
            right : null,
          },
          is_gold : false
        }
      },
      room_detail: {},
      booths: [
        ["A1", "A2", "A3","A4"],
        ["B1", "B2", "B3","B4"],
        ["C1", "C2", "C3","C4"],
        // ["A1", "B1", "C1"],
        // ["A2", "B2", "C2"],
        // ["A3", "B3", "C3"],
        // ["A4", "B4", "C4"],
      ],
      loading: false,
      data: {},
    };
  },
  methods: {
    ...mapMutations("utils", ["setAlert"]),
    getVendorList() {
      let _self = this;
      this.$axios
        .get("/admin/vendor/list?pagination=false")
        .then((response) => {
          _self.vendors = response.data.data;
        })
        .catch(function () {});
    },
    assignBooth(booth_data,booth_id,selected_vendor,from_select) {
      let _self = this;
      booth_data['room_id'] = _self.$route.params.room_id
      booth_data['booth_id'] = booth_id
      booth_data['selected_vendor'] = selected_vendor
      if(booth_data.vendor_ids.left==booth_data.vendor_ids.right){
        booth_data.vendor_ids = {
          left : from_select=='right'?null:booth_data.vendor_ids.left,
          right : from_select=='left'?null:booth_data.vendor_ids.right
        }
      }
      this.$axios.post(`admin/vendor_room/booth/assign`, booth_data)
        .then((response) => {
          let response_data = response.data;
          if (response_data.status) {
            _self.setAlert({
              show: true,
              position: "top",
              variant: "success",
              message: response_data.message,
            });
          } else {
            let errors = response_data.error;
            _self.setAlert({
              show: true,
              variant: "red darken-4",
              message: errors.message,
              dismissCountDown: 5000,
            });
          }
          _self.getRoomDetails();
          _self.loading = false;
        })
        .catch((e) => {
          console.log(e);
          _self.loading = false;
        });
    },
    getRoomDetails() {
      let _self = this;
      this.loading = "accent";
      this.$axios
        .get("/admin/vendor_room/details/" + _self.$route.params.room_id)
        .then((response) => {
          let data = response.data.data;
          let room_vendors = response.data.room_vendors;
          _self.room_detail = data;
          let booth_data = _self.booth_data;
          for(let i = 0; i<room_vendors.length ; i++){
            booth_data[room_vendors[i].booth_id] = room_vendors[i];
          }
          _self.$nextTick(()=>{
            _self.booth_data = booth_data;
          })
          
          console.log('_self.booth_data',_self.booth_data)
          _self.loading = false;
        })
        .catch(function () {})
        .then(() => {
          _self.loading = false;
        });
    },
  },
  mounted: function () {
    this.getVendorList();
    this.getRoomDetails();
  },
};
</script>